import Vue from 'vue';
import Endpoints from "@/share/Endpoints";

export const fetchEventById = async ( {commit}, eventId ) => {
  try {
    let {data} = await Vue.axios({
      method: 'GET',
      url: `/events/${eventId}`
    });
    commit('setEventSelected', data);
    return data;
  } catch (error) {
    return {message: error, status: false};
  }
}

export const postEventReminder = async ({commit},{eventId, reminder}) => {
  console.log(reminder);
  try {
    let {data} = await Vue.axios({
      method: 'POST',
      url: `/events/set-event-reminder/${eventId}`,
      data: {
        ...reminder
      }
    })

    return data
  } catch (error) {
    return {message: error, status: false};
  }
}

export const deleteEventReminder = async ({commit}, {eventId, reminderId}) => {
  try {
    let {data} = await Vue.axios({
      method: 'DELETE',
      url: `/events/event-reminder/${eventId}/${reminderId}`,
    })

    return data
  } catch (error) {
    return {message: error, status: false};
  }
}


export const fetchMicrositeData = async ( {commit}, eventId ) => {
  try {
    let {data} = await Vue.axios({
      method: 'GET',
      url: `${Endpoints.micrositeData}/${eventId}`
    });
    console.log("Datos que obtengo del micrositio");
    console.log(data);
    commit('setMicrositeData', data.event);
    return data;
  } catch (error) {
    return {message: error, status: false};
  }
}

export const fetchOrganizationEventsPaginated = async ( {commit}, eventsSearchData ) => {
  try {
    let {data} = await Vue.axios({
      method: 'GET',
      url: `/events/organization?page=${eventsSearchData.page}&limit=${eventsSearchData.perPage}&name=${eventsSearchData.name}`
    });
    if (data.message && data.message.name && data.message.name == "TokenExpiredError") {
      return {message: "El token a expirado", status: false};
    } else {
      commit('setOrganizationEventsPaginated', data);
    }
    return {message: "Eventos obtenidos de forma correcta", status: true, data: data};
  } catch (error) {
    return {message: error, status: false};
  }
}

export const fetchInvitationalEventsPaginated = async ( {commit}, eventsSearchData ) => {
  try {
    let {data} = await Vue.axios({
      method: 'GET',
      url: `/events/invitationals?page=${eventsSearchData.page}&limit=${eventsSearchData.perPage}&name=${eventsSearchData.name}`
    });
    if (data.message && data.message.name && data.message.name == "TokenExpiredError") {
      return {message: "El token a expirado", status: false};
    } else {
      commit('setOrganizationEventsPaginated', data);
    }
    return {message: "Eventos obtenidos de forma correcta", status: true, data: data};
  } catch (error) {
    return {message: error, status: false};
  }
}

export const fetchOrganizationEvents = async ( {commit}, eventsSearchData ) => {
  try {
    let {data} = await Vue.axios({
      method: 'GET',
      url: `/events/organization?page=${eventsSearchData.page}&limit=${eventsSearchData.perPage}`
    });
    if (data.message && data.message.name && data.message.name == "TokenExpiredError") {
      return {message: "El token ha expirado", status: false};
    } else {
      commit('setOrganizationEvents', data.events);
    }
    return {message: "Eventos obtenidos de forma correcta", status: true};
  } catch (error) {
    return {message: error, status: false};
  }
}

export const createEvent = async ( {commit}, dataEvent) => {
  dataEvent.tickets.billingInformation 
  try {
    let response = await Vue.axios({
      method: 'POST',
      url: '/events/create',
      data: {
        name: dataEvent.name,
        description: dataEvent.description,
        dateConfiguration: dataEvent.dateConfiguration,
        recurrent: dataEvent.recurrent,
        eventModality: dataEvent.eventModality,
        type: dataEvent.type,
        category: dataEvent.category,
        micrositeConfiguration: dataEvent.micrositeConfiguration,
        presentialEventConfiguration: dataEvent.presentialEventConfiguration,
        virtualEventConfiguration: dataEvent.virtualEventConfiguration,
        amenities: dataEvent.amenities,
        tags: dataEvent.tags,
        paymentModality: dataEvent.paymentModality,
        installments: dataEvent.installments,
        offerBilling: dataEvent.offerBilling,
        comissionTransfer: dataEvent.comissionTransfer,
        tickets: dataEvent.tickets,
        organization: dataEvent.organization,
        ticketsPerOrder: dataEvent.ticketsPerOrder==0?15:dataEvent.ticketsPerOrder,
        invitationalEvent: dataEvent.invitationalEvent,
      }
    });
    return response;
  } catch (error) {
    return error;
  }
}

export const updateEvent = async ( {commit}, dataEvent) => {
  try {
    let response = await Vue.axios({
      method: 'PUT',
      url: `/events/update/${dataEvent._id}`,
      data: {
        dataEvent,
      },
    });
    return { status: true, message: "Evento actualizado correctamente", data: response.data };
  } catch (error) {
    return {status: false, message: "No se actualizó el evento correctamente"};
  }
}

export const deleteEvent = async ( {commit}, eventId) => {
  try {
    let response = await Vue.axios({
      method: 'DELETE',
      url: `${Endpoints.events}/${eventId}`
    });
    return {status: true, message: "Evento eliminado correctamente", data: response}
  } catch (error) {
    return {status: false, message: error}
  }
}

export const publishEvent = async ( {commit}, dataEvent) => {
  console.log(dataEvent);
  try {
    let response = await Vue.axios({
      method: 'PUT',
      url: `/events/update/${dataEvent.id}`,
      data: {
        dataEvent
      },
    });
    console.log(response);
    return { status: true, message: "Evento actualizado correctamente", data: response.data };
  } catch (error) {
    console.log(error);
    return {status: false, message: "No se actualizó el evento correctamente"};
  }
}

/**
 * Función para agregar una imagen de un patrocinador a un evento
 * @param {String} sponsorData Datos del patrocinador y del evento
 */
export const addEventSponsor = async ( {commit}, sponsorData) => {
  try {
    let response = await Vue.axios({
      method: 'POST',
      url: `${Endpoints.addSponsor}/${sponsorData.eventId}`,
      data: {
        name: sponsorData.name,
        logo: sponsorData.logo,
        url: sponsorData.url,
      }
    });
    return response;
  } catch (error) {
    return error;
  }
}

/**
 * Función para remover una imagen de un patrocinador de un evento
 * @param {String} sponsorData Datos del patrocinador y del evento
 */
 export const removeEventSponsor = async ( {commit}, sponsorData) => {
  try {
    let response = await Vue.axios({
      method: 'POST',
      url: `${Endpoints.removeSponsor}/${sponsorData.eventId}`,
      data: {
        sponsorId: sponsorData.sponsorId,
        logo: sponsorData.logo,
      }
    });
    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
}

export const fetchEventFaqs = async ( {commit}, eventId ) => {
  try {
    let {data} = await Vue.axios({
      method: 'GET',
      url: `${Endpoints.faqs}/${eventId}`
    });
    commit('setEventFaqs', data.faqs);
    return data;
  } catch (error) {
    return {message: error, status: false};
  }
}

export const createEventFaq = async ( {commit}, dataFaq) => {
  try {
    let response = await Vue.axios({
      method: 'POST',
      url: Endpoints.faqs,
      data: {
        faq: {
          question: dataFaq.question,
          answer: dataFaq.answer,
          event: dataFaq.eventId,
        }
      }
    });
    return {status: true, message: "Pregunta frecuente creada correctamente", data: response};
  } catch (error) {
    return {status: false, message: error}
  }
}

export const updateEventFaq = async ( {commit}, dataFaq) => {
  try {
    let response = await Vue.axios({
      method: 'PUT',
      url: `${Endpoints.faqs}/${dataFaq.id}`,
      data: {
        faq: {
          question: dataFaq.question,
          answer: dataFaq.answer,
          status: dataFaq.status,
          event: dataFaq.event,
        }
      }
    });
    return {status: true, message: "Pregunta frecuente actualizada correctamente", data: response};
  } catch (error) {
    return {status: false, message: error}
  }
}

export const deleteEventFaq = async ( {commit}, dataFaq) => {
  try {
    let response = await Vue.axios({
      method: 'DELETE',
      url: `${Endpoints.faqs}/${dataFaq.id}`,
      data: {
        faq: {
          event: dataFaq.event,
        }
      }
    });
    return {status: true, message: "Pregunta frecuente eliminada correctamente", data: response};
  } catch (error) {
    return {status: false, message: error}
  }
}