<template>
  <v-app class="main-container">
    <v-sheet class="overflow-hidden" style="z-index: 8" color="#f9fafc" height="100%">
      <landing-navbar v-if="
        user &&
        user.role != 'ORGANIZATION' &&
        user.role != 'HEAD_OFFICE' &&
        user.role != 'ORGANIZATION_USER' &&
        user.role != 'BRANCH' &&
        !$route.fullPath.includes('micrositio') &&
        !$route.fullPath.includes('checkout') &&
        !$route.fullPath.includes('member') &&
        !$route.fullPath.includes('matrix') &&
        !$route.fullPath.includes('affiliation-checkout') &&
        !$route.fullPath.includes('credential') &&
        !$route.fullPath.includes('registro') &&
        !$route.fullPath.includes('voucher') &&
        (!$route.meta.noRedirect ||
          $route.fullPath.includes('facturacion-cliente'))
      " />
      <!-- head office nav bar -->
      <dashboard-navbar v-if="
        showMainNavbar &&
        !$route.fullPath.includes('micrositio') &&
        !$route.fullPath.includes('credential') &&
        !$route.fullPath.includes('affiliation-checkout') &&
        !$route.fullPath.includes('registro') &&
        !$route.fullPath.includes('voucher') &&
        !$route.meta.noRedirect
      " />
      <detail-navbar v-if="$route.fullPath.includes('mi-evento') && !$route.meta.noRedirect" entityType="Event"
        :navigations="eventsNavigation" />
      <!-- member nav var -->
      <side-bar v-if="
        $route.fullPath.includes('member') &&
        !$route.fullPath.includes('credential') &&
        !$route.fullPath.includes('micrositio') &&
        !$route.fullPath.includes('affiliation-checkout') &&
        !$route.fullPath.includes('voucher') &&
        $route.name != 'Renew Membership' &&
        $route.name != 'Member new Checkout' &&
        !$route.fullPath.includes('affiliation-profile') &&
        !$route.meta.noRedirect
      " :items="membersItems" />

      <detail-navbar v-if="$route.fullPath.includes('mi-membresia')" entityType="Affiliation"
        :navigations="affiliationsNavigation" />
      <detail-navbar v-if="
        $route.fullPath.includes('mi-capitulo') && !$route.meta.noRedirect
      " entityType="Chapter" :navigations="chaptersNavigation" />
      <!-- branch nav bar -->
      <BranchNavbar v-if="
        user.role == 'BRANCH' &&
        !$route.fullPath.includes('mi-evento') &&
        !$route.fullPath.includes('mi-membresia') &&
        !$route.meta.noRedirect
      " entityType="Chapter" :navigations="chaptersNavigation" />
      <v-main class="bg-color secondary-container">
        <router-view />
      </v-main>
    </v-sheet>
    <!-- ALERTAS DE SITIO -->
    <div class="siteAlerts">
      <BoxAlerts :allAlertsInformation="setAlertsOfSite"></BoxAlerts>
    </div>
    <!-- Aviso de politica de cookies -->
    <CookiesAdvice></CookiesAdvice>

    <!-- NOTIFICACIONES DEL SITIO -->
    <v-snackbar width="400" :multi-line="true" :top="true" :right="true" :timeout="time" v-model="isVisible"
      :color="color" style="z-index: 249" rounded="xl" transition="slide-x-reverse-transition">
      <span class="subtitle-2 font-weight-bold">{{ text }}</span>
    </v-snackbar>
    <!-- NOTIFICACIONES DEL SITIO -->
    <!--Inicio de loader del sitio-->
    <v-overlay :value="loaderVisible" style="z-index: 250">
      <the-loader />
    </v-overlay>
    <!--Fin de loader del sitio-->
  </v-app>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import LandingNavbar from "@/components/landing/LandingNavbar";
import LandingFooter from "@/components/landing/LandingFooter";
import DashboardNavbar from "@/components/dashboard/DashboardNavbar";
//import DashboardNavBottom from "@/components/dashboard/DashboardNavBottom";
import DetailNavbar from "@/components/dashboard/DetailNavbar";
import TheLoader from "@/components/shared/TheLoader";
import sideBar from "@/components/memberDashboard/sideBar";
import CookiesAdvice from "@/components/shared/CookiesAdvice";
import BranchNavbar from "@/components/dashboard/BranchNavbar";
import Vue from "vue";
import { colors } from "@/constants/colors.js";
import BoxAlerts from "./components/shared/BoxAlerts.vue";

export default {
  name: "App",
  components: {
    LandingNavbar,
    LandingFooter,
    DashboardNavbar,
    //DashboardNavBottom,
    DetailNavbar,
    TheLoader,
    sideBar,
    CookiesAdvice,
    BranchNavbar,
    BoxAlerts,
  },
  computed: {
    ...mapState("user", ["user"]),
    ...mapState("member", ["credential"]),
    ...mapState("notification", ["text", "color", "time", "visible"]),
    ...mapState("loader", ["loaderVisible"]),
    ...mapState("alert", ["alertVisible"]),
    isVisible: {
      get() {
        return this.visible;
      },
      set(value) {
        this.hide();
      },
    },
    setAlertsOfSite() {
      return [
        {
          alertColor: colors.secondary,
          message: "Recuerda actualizar los datos de tu cuenta bancaria",
          icon: "mdi-alert",
          link: "/mi-cuenta",
          isVisible:
            ["HEAD_OFFICE", "ORGANIZATION", "BRANCH"].includes(
              this.user.role
            ) &&
            (!this.user.stripeAccountType ||
              this.user.stripeAccountType != "EXPRESS"),
          buttonMessage: "Actualizar",
        },
        {
          alertColor: colors.secondary,
          message: "Requiere integración de cuenta bancaria",
          icon: "mdi-alert",
          link: "/mi-cuenta",
          isVisible:
            ["HEAD_OFFICE", "ORGANIZATION", "BRANCH"].includes(
              this.user.role
            ) && this.user.accountSetup == "NOT_STARTED",
          buttonMessage: "Integrar",
        },
        {
          alertColor: "red",
          message:
            "Esta organización aún no integra sus sellos fiscales para ofrecer facturación",
          icon: "mdi-alert-circle",
          link: "/mi-cuenta",
          isVisible:
            ["HEAD_OFFICE", "ORGANIZATION", "BRANCH"].includes(
              this.user.role
            ) && !this.user.invoiceSetup,
          buttonMessage: "Integrar",
        },
        {
          alertColor: "green",
          message:
            "El 1 de abril de 2023 estaremos migrando a la facturación 4.0, favor de actualizar tus datos fiscales antes de esa fecha, de lo contrario no podrás emitir facturas, ASEGÚRATE QUE LOS DATOS SEAN IDÉNTICOS A LOS DE TU CONSTANCIA DE SITUACIÓN FISCAL.",
          icon: "mdi-archive-alert",
          link: "/mi-cuenta/?tab=2",
          isVisible:
            ["HEAD_OFFICE", "ORGANIZATION", "BRANCH"].includes(
              this.user.role
            ) && !this.user.billingData, //modificar reloadUserData
          buttonMessage: "Actualizar",
        },
        {
          alertColor: colors.secondary,
          message:
            "No olvides cargar tus datos fiscales, editándolos en tu perfil, para que cuando requieras factura se carguen de forma automática",
          icon: "mdi-alert",
          link: "/member/home/?tab=2",
          isVisible:
            ["MEMBER", "MEMBERSHIP_USER"].includes(this.user.role) &&
            this.$router.name == "Renew Membership" &&
            (!this.user.fiscalData ||
              Object.values(this.user.fiscalData).some((data) => data === "")),
          buttonMessage: "CARGAR DATOS",
        },
      ];
    },
    showMainNavbar() {
      //console.log("ROM",this.user)
      const routeIncludes = (route) => this.$route.fullPath.includes(route);
      return (
        this.user &&
        (this.user.role === "ORGANIZATION" ||
          this.user.role === "HEAD_OFFICE" ||
          this.user.role === "ORGANIZATION_USER") &&
        !routeIncludes("mi-evento") &&
        !routeIncludes("mi-membresia") &&
        !routeIncludes("mi-capitulo")
      );
    },
    membersItems() {
      if (
        this.user.role === "MEMBERSHIP_USER" ||
        (this.credential &&
          this.credential.credentialInformation &&
          this.credential.credentialInformation.externalId &&
          this.credential.credentialInformation.externalId.substring(0, 2) ===
          "AF")
      ) {
        return this.membershipUsersNavigationComputed;
      } else if (this.user.role === "MEMBER") {
        return this.membersNavigationComputed;
      }
    },
    membersNavigationComputed() {
      let disabledNavigation = this.membersNavigation;
      if (this.user.validationStatus === "ACTIVE") {
        return this.membersNavigation;
      } else {
        for (let i = 0; i < disabledNavigation.length; i++) {
          disabledNavigation[i].disabled = true;
        }
        return disabledNavigation;
      }
    },
    membershipUsersNavigationComputed() {
      const disabledNavigation = this.membershipUsersNavigation;
      if (
        this.user.affiliation.national &&
        this.user.affiliation.national.details &&
        this.user.affiliation.local &&
        this.user.affiliation.local.details
      ) {
        if (
          this.user.validationStatus == "ACTIVE"
        ) {
          return this.membershipUsersNavigation;
        }
      }
      for (let i = 1; i < disabledNavigation.length; i++) {
        disabledNavigation[i].disabled = true;
      }
      return disabledNavigation;
    },
    ableToRenew() {
      //if between today and 90 days before expiration date is true
      const today = new Date();
      const expirationDate = new Date(
        this.user.affiliation.local.details.affiliateInformation.expirationDate
      );
      const ninetyDaysBeforeExpirationDate = new Date(
        expirationDate.setDate(expirationDate.getDate() - 90)
      );
      return (
        today >= ninetyDaysBeforeExpirationDate &&
        this.user.affiliation.local.details.status === "ACTIVE"
      );
    },
  },
  data() {
    return {
      colorPalete: colors,
      loggedIn: false,
      site: "",
      eventsNavigation: [
        //{ icon: 'fas fa-chevron-left', title: 'Volver al inicio', link: '/mis-eventos' },
        //{ icon: 'mdi-view-dashboard', title: 'Panel de evento', name: 'panel-evento' },
        {
          icon: "fas fa-user-friends",
          title: "Asistentes",
          name: "asistentes",
        },
        {
          icon: "far fa-calendar-alt",
          title: "Micrositio",
          name: "micrositio",
        },
        {
          icon: "fas fa-ticket-alt",
          title: "Accesos y ventas",
          name: "tickets-ventas",
        },
        {
          icon: "fas fa-cog",
          title: "Personalización",
          name: "personalization",
        },
      ],
      affiliationsNavigation: [
        //{ icon: "fas fa-user-friends", title: "Miembros", name: "members" },
        {
          icon: "far fa-calendar-alt",
          title: "Micrositio",
          name: "microsite-affiliation",
        },
        {
          icon: "fas fa-ticket-alt",
          title: "Niveles de Membresía y ventas",
          name: "tickets-sales-affiliation",
        },
        {
          icon: "fas fa-cog",
          title: "Personalización",
          name: "affiliation-personalization",
        },
      ],
      chaptersNavigation: [
        {
          icon: "mdi-view-dashboard",
          title: "Panel Local",
          name: "local-panel",
        },
        { icon: "event", title: "Mis Eventos", name: "events" },
        { icon: "badge", title: "Mis Miembros", name: "miembros" },
      ],
      membersNavigation: [
        {
          title: "Inicio",
          icon: "mdi-view-dashboard",
          route: "/member/home",
          disabled: false,
        },
        {
          title: "Histórico",
          icon: "mdi-book-open-page-variant",
          route: "/member/history",
          disabled: false,
        },
        {
          title: "Afiliados vinculados",
          icon: "mdi-account-multiple",
          route: "/member/affiliates",
          disabled: false,
        },
        {
          title: "Cursos y Eventos",
          icon: "mdi-school",
          route: "/member/events",
          disabled: false,
        },
        {
          title: "Mi Comunidad",
          icon: "mdi-radio",
          route: "/member/community",
          disabled: false,
        },
        {
          title: "Mis Beneficios",
          icon: "mdi-hand-coin-outline",
          route: "/member/benefits",
          disabled: false,
        },
      ],
      membershipUsersNavigation: [
        {
          title: "Inicio",
          icon: "mdi-view-dashboard",
          route: "/member/home",
          disabled: false,
        },
        {
          title: "Histórico",
          icon: "mdi-book-open-page-variant",
          route: "/member/history",
          disabled: false,
        },
        {
          title: "Cursos y Eventos",
          icon: "mdi-school",
          route: "/member/events",
          disabled: false,
        },
      ],
    };
  },
  methods: {
    ...mapMutations("notification", ["hide"]),
    ...mapActions("user", ["realoadUserData"]),
  },
  mounted() {
    this.$gtag.screenview({
      app_name: "App",
      screen_name: "Home page",
    });
    this.site = this.$route.fullPath.split("/")[1];
    if (this.user && this.user.email) {
      this.realoadUserData({ email: this.user.email });
    }
  },
};
</script>
<style>
@import "~shepherd.js/dist/css/shepherd.css";
@import url("https://fonts.googleapis.com/css?family=Montserrat:100,300,400,500,700,900&display=swap");
@import url("https://fonts.googleapis.com/css?family=Muli:100,300,400,500,700,900&display=swap");

* {
  font-family: "Montserrat", sans-serif;
}

.siteAlerts {
  position: fixed !important;
  bottom: 0px;
  right: 0px;
  z-index: 10;
}

.primary-variant {
  color: #1b273e;
}

.wc-h1 {
  font-family: "Montserrat", sans-serif;
  font-weight: 900;
  font-size: 50px;
}

.wc-h2 {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 45px;
}

.wc-h3 {
  font-family: "Montserrat", sans-serif;
  font-weight: 900;
  font-size: 30px;
}

.wc-h4 {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 30px;
}

.wc-h5 {
  font-family: "Montserrat", sans-serif;
  font-weight: 900;
  font-size: 25px;
  letter-spacing: 0.008em;
}

.wc-h6 {
  font-family: "Montserrat", sans-serif;
  font-weight: 900;
  font-size: 20px;
  letter-spacing: 0.008em;
}

.wc-subtitle-1 {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 20px;
  letter-spacing: 0.009em;
}

.wc-subtitle-2 {
  font-family: "Muli", sans-serif;
  font-weight: 700;
  font-size: 18px;
  letter-spacing: 0.007em;
}

.wc-body-1 {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 18px;
  letter-spacing: 0.03em;
}

.wc-body-2 {
  font-family: "Muli", sans-serif;
  font-weight: 300;
  font-size: 16px;
  letter-spacing: 0.03em;
}

.wc-button {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0.018em;
}

.wc-caption-1 {
  font-family: "Muli", sans-serif;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.033em;
}

.wc-caption-2 {
  font-family: "Muli", sans-serif;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.033em;
}

.wc-overline {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0.15em;
}

.text-primary {
  color: #24b2da !important;
}

.text-primary-variant {
  color: #253656 !important;
}

.text-disabled {
  color: #8c929e !important;
}

.text-active {
  color: #9bf4d5 !important;
}

.text-secondary {
  color: #ffb500 !important;
}

.text-secondary-variant {
  color: #fce09e !important;
}

.text-gray {
  color: #d8d8d8 !important;
}

.text-background {
  color: #f9fafc !important;
}

.text-surface {
  color: #ffffff !important;
}

.text-error {
  color: #fcbd9e !important;
}

.text-contrast-error {
  color: #c46130 !important;
}

.clickable {
  cursor: pointer;
}
</style>