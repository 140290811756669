import Vue from 'vue';
import Endpoints from "@/share/Endpoints";

export const fetchEventTickets = async ({ commit }, ticketsSearchData) => {
    try {
        let { data } = await Vue.axios({
            method: 'GET',
            url: `/tickets/${ticketsSearchData.eventId}?page=${ticketsSearchData.page}&limit=${ticketsSearchData.perPage}`
        });
        commit('setEventTickets', data.tickets);
        return data;
    } catch (error) {
        return { message: error, status: false };
    }
}

export const fetchEventTicketsList = async ({ commit }, ticketsSearchData) => {
    try {
        let { data } = await Vue.axios({
            method: 'GET',
            url: `/tickets/${ticketsSearchData.eventId}?page=${ticketsSearchData.page}&limit=${ticketsSearchData.perPage}`
        });
        commit('setListEventTickets', data);
        return data;
    } catch (error) {
        return { message: error, status: false };
    }
}

export const createEventTicket = async ({ commit }, access) => {
    try {
        let response = await Vue.axios({
            method: 'POST',
            url: '/tickets',
            data: {
                ticket: {
                    modality: access.modality,
                    name: access.name,
                    price: access.price,
                    quantity: access.quantity,
                    details: access.details,
                    status: "ACTIVE",
                    event: access.event,
                    billingInformation: access.billingInformation
                }
            }
        });
        return response;
    } catch (error) {
        return { message: error, status: false };
    }
}

export const updateEventTicket = async ({ commit }, access) => {
    try {
        let response = await Vue.axios({
            method: 'PUT',
            url: `/tickets/${access.id}`,
            data: {
                ticket: {
                    modality: access.modality,
                    name: access.name,
                    price: access.price,
                    quantity: access.quantity,
                    details: access.details,
                    status: access.status,
                    event: access.event,
                    membersOnly: access.membersOnly,
                    hidAccess:access.hidAccess,

                    billingInformation: access.billingInformation
                }
            }
        });
        return response;
    } catch (error) {
        return { message: error, status: false };
    }
}

export const deleteEventTicket = async ({ commit }, access) => {
    try {
        console.log("Datos del acceso seleccionado");
        console.log(access);
        let response = await Vue.axios({
            method: 'DELETE',
            url: `/tickets/${access.id}`,
            data: {
                event: access.event
            }
        });
        return response;
    } catch (error) {
        return { message: error, status: false };
    }
}

export const assignItemsToTicket = async ({ commit }, access) => {
    try {
        let response = await Vue.axios({
            method: 'PUT',
            url: `${Endpoints.tickets}/${access.id}/assign`,
            data: {
                ticket: {
                    itemQuantity: access.itemQuantity,
                    event: access.event
                }
            }
        });
        return response;
    } catch (error) {
        return { message: error, status: false };
    }
}

export const unassignItemsToTicket = async ({ commit }, access) => {
    try {
        let response = await Vue.axios({
            method: 'PUT',
            url: `${Endpoints.tickets}/${access.id}/unassign`,
            data: {
                ticket: {
                    itemQuantity: access.itemQuantity * -1,
                    event: access.event
                }
            }
        });
        return response;
    } catch (error) {
        console.log("eeeeeerrorr");
        console.log(error);
        return { message: error, status: false };
    }
}

export const updateDiplomaConfiguration = async ({ commit }, accessData) => {
    try {
        const formData = new FormData();
        let { top, left } = {
            top: accessData.orientation == "horizontal" ? 2.34 : 2.56,
            left: accessData.orientation == "horizontal" ? 2.58 : 2.35,
        };
        //console.log(accessData);
        if (accessData.diplomaTemplate) {
            formData.append("diplomaTemplate", accessData.diplomaTemplate);
        }
        formData.append("orientation", accessData.orientation);
        formData.append("marginTop", Math.round((accessData.marginTop * top - 40)));
        formData.append("marginLeft", Math.round(accessData.marginLeft * left));
        formData.append("nameDelimeter", Math.round(accessData.nameDelimeter * left));
        formData.append("textColor", accessData.textColor);
        formData.append("event", accessData.event);
        console.log(formData);
        let response = await Vue.axios({
            method: 'PUT',
            url: `${Endpoints.tickets}/${accessData.accessId}/diploma-configuration`,
            data: formData
        });
        return response;
    } catch (error) {
        return { message: error, status: false };
    }
}