var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',{staticClass:"main-container"},[_c('v-sheet',{staticClass:"overflow-hidden",staticStyle:{"z-index":"8"},attrs:{"color":"#f9fafc","height":"100%"}},[(
      _vm.user &&
      _vm.user.role != 'ORGANIZATION' &&
      _vm.user.role != 'HEAD_OFFICE' &&
      _vm.user.role != 'ORGANIZATION_USER' &&
      _vm.user.role != 'BRANCH' &&
      !_vm.$route.fullPath.includes('micrositio') &&
      !_vm.$route.fullPath.includes('checkout') &&
      !_vm.$route.fullPath.includes('member') &&
      !_vm.$route.fullPath.includes('matrix') &&
      !_vm.$route.fullPath.includes('affiliation-checkout') &&
      !_vm.$route.fullPath.includes('credential') &&
      !_vm.$route.fullPath.includes('registro') &&
      !_vm.$route.fullPath.includes('voucher') &&
      (!_vm.$route.meta.noRedirect ||
        _vm.$route.fullPath.includes('facturacion-cliente'))
    )?_c('landing-navbar'):_vm._e(),(
      _vm.showMainNavbar &&
      !_vm.$route.fullPath.includes('micrositio') &&
      !_vm.$route.fullPath.includes('credential') &&
      !_vm.$route.fullPath.includes('affiliation-checkout') &&
      !_vm.$route.fullPath.includes('registro') &&
      !_vm.$route.fullPath.includes('voucher') &&
      !_vm.$route.meta.noRedirect
    )?_c('dashboard-navbar'):_vm._e(),(_vm.$route.fullPath.includes('mi-evento') && !_vm.$route.meta.noRedirect)?_c('detail-navbar',{attrs:{"entityType":"Event","navigations":_vm.eventsNavigation}}):_vm._e(),(
      _vm.$route.fullPath.includes('member') &&
      !_vm.$route.fullPath.includes('credential') &&
      !_vm.$route.fullPath.includes('micrositio') &&
      !_vm.$route.fullPath.includes('affiliation-checkout') &&
      !_vm.$route.fullPath.includes('voucher') &&
      _vm.$route.name != 'Renew Membership' &&
      _vm.$route.name != 'Member new Checkout' &&
      !_vm.$route.fullPath.includes('affiliation-profile') &&
      !_vm.$route.meta.noRedirect
    )?_c('side-bar',{attrs:{"items":_vm.membersItems}}):_vm._e(),(_vm.$route.fullPath.includes('mi-membresia'))?_c('detail-navbar',{attrs:{"entityType":"Affiliation","navigations":_vm.affiliationsNavigation}}):_vm._e(),(
      _vm.$route.fullPath.includes('mi-capitulo') && !_vm.$route.meta.noRedirect
    )?_c('detail-navbar',{attrs:{"entityType":"Chapter","navigations":_vm.chaptersNavigation}}):_vm._e(),(
      _vm.user.role == 'BRANCH' &&
      !_vm.$route.fullPath.includes('mi-evento') &&
      !_vm.$route.fullPath.includes('mi-membresia') &&
      !_vm.$route.meta.noRedirect
    )?_c('BranchNavbar',{attrs:{"entityType":"Chapter","navigations":_vm.chaptersNavigation}}):_vm._e(),_c('v-main',{staticClass:"bg-color secondary-container"},[_c('router-view')],1)],1),_c('div',{staticClass:"siteAlerts"},[_c('BoxAlerts',{attrs:{"allAlertsInformation":_vm.setAlertsOfSite}})],1),_c('CookiesAdvice'),_c('v-snackbar',{staticStyle:{"z-index":"249"},attrs:{"width":"400","multi-line":true,"top":true,"right":true,"timeout":_vm.time,"color":_vm.color,"rounded":"xl","transition":"slide-x-reverse-transition"},model:{value:(_vm.isVisible),callback:function ($$v) {_vm.isVisible=$$v},expression:"isVisible"}},[_c('span',{staticClass:"subtitle-2 font-weight-bold"},[_vm._v(_vm._s(_vm.text))])]),_c('v-overlay',{staticStyle:{"z-index":"250"},attrs:{"value":_vm.loaderVisible}},[_c('the-loader')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }