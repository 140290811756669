export function homologateStatusWithColor(status) {
  switch (status) {
    case "PROSPECTO":
      return { color: "#fce09e", status: "Prospecto" };
      break;
    case "INACTIVO":
      return { color: "#fad0bf", status: "Inactivo" };
      break;
    case "ACTIVE":
      return { color: "#9bf4d5", status: "Activo" };
      break;
    case "EXPIRED":
      return { color: "#f2f2f2", status: "Expirado" };
      break;
    case "DESARROLLADOR":
      return { color: "#e2cffc", status: "Desarrollador" };
      break;
    default:
      return { color: "#88f4d8", status: status };
  }
}

export function isActivePaymentNationalH(affiliation) {
  if (affiliation) {
    if (affiliation.national && affiliation.national._id) {
      if (affiliation.national.details) {
        let status = affiliation.national.details[0].status;
        let affiliateInformation =
          affiliation.national.details[0].affiliateInformation;

        return ableToRenewH(affiliateInformation, status);
      }
      return true;
    }
  }
  return false;
}

export function isActivePaymentLocalH(affiliation) {
  if (affiliation) {
    if (affiliation.local && affiliation.local._id) {
      if (affiliation.local.details) {
        let status = affiliation.local.details[0].status;
        let affiliateInformation =
          affiliation.local.details[0].affiliateInformation;
        return ableToRenewH(affiliateInformation, status);
      }
      return true;
    }
  }
  return false;
}

export function ableToRenewH(affiliateInformation, status) {
  if (affiliateInformation) {
    const today = new Date();
    let expiration =
      affiliateInformation.inactiveDate != undefined
        ? affiliateInformation.inactiveDate
        : affiliateInformation.expirationDate;

    const expirationDate = new Date(expiration);
    expirationDate.setDate(expirationDate.getDate() - 90);
    console.log(expirationDate);

    let rob = false;
    if (today >= expirationDate) {
      rob = true;
    }
    console.log(rob);

    return today >= expirationDate;
  }
  return false;
}
