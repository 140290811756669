<template>
  <v-row justify="center">
    <v-dialog
      v-model="open"
      :width="$vuetify.breakpoint.mdAndUp ? '70vw' : '100vw'"
      @click:outside="$emit('close')"
      scrollable
    >
      <v-card>
        <v-card-text class="px-3 px-sm-5 px-md-10 py-3 py-sm-5 py-sm-10">
          <v-container>
            <v-row v-if="$vuetify.breakpoint.smAndDown">
              <v-col cols="12" class="d-flex justify-end pb-0">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      elevation="0"
                      fab
                      dark
                      color="red"
                      x-small
                      v-bind="attrs"
                      v-on="on"
                      @click="$emit('close')"
                    >
                      <v-icon dark> mdi-close </v-icon>
                    </v-btn>
                  </template>
                  <span>Cerrar</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <div class="d-flex justify-center align-center">
                  <v-img
                    v-if="$vuetify.breakpoint.mdAndUp"
                    src="@/assets/logo.png"
                    max-width="100"
                    max-height="100"
                    class="left-img"
                  />
                  <div class="text-center">
                    <h1>¡Recarga!</h1>
                    <h2
                      class="mt-5"
                      :class="
                        $vuetify.breakpoint.mdAndUp ? 'subheader' : 'subtitle-1'
                      "
                    >
                      Elige la cantidad de créditos que necesitas, los cuales se
                      abonarán de inmediato en tu tablero.
                    </h2>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" class="pa-sm-7">
                <v-row justify="end">
                  <v-col
                    cols="12"
                    id="draggable-scroll"
                    @mousedown="mouseDownHandler"
                    class="service-scroll d-flex"
                  >
                    <div v-for="(item, i) in plans" :key="i">
                      <v-hover v-slot="{ hover }">
                        <v-card
                          height="530"
                          width="220"
                          :color="item.color"
                          :elevation="hover ? 12 : 0"
                          :class="{ 'on-hover': hover }"
                          class="rounded-xl ma-3 plan-card"
                        >
                          <div v-if="item.name == 'Avanzado'" class="py-4">
                            <v-card-subtitle
                              style="font-size: 16px"
                              class="text-center pa-0 pb-1 font-weight-black"
                            >
                              Módulo de <br />
                              membresías
                            </v-card-subtitle>

                            <v-list color="transparent" dense>
                              <v-list-item
                                v-for="item in advancep"
                                :key="item"
                                class="pa-0"
                              >
                                <v-list-item-icon class="ma-auto">
                                  <v-icon class="mx-2" color="green"
                                    >mdi-check-bold</v-icon
                                  >
                                </v-list-item-icon>
                                <v-list-item-content class="pa-0">
                                  <p class="mb-0 pa-0" style="font-size: 15px">
                                    {{ item }}
                                  </p>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                            <v-card-text class="pa-0">
                              <p
                                style="
                                  font-size: 25px;
                                  font-weight: bold;
                                  line-height: 0.7;
                                  text-align: center;
                                "
                              >
                                $ 300.00 MXN <br /><span style="font-size: 10px"
                                  >x miembro al año</span
                                >
                              </p>
                              <br />

                              <div class="text-center">
                                <span class="caption"
                                  >+ Comisión del procesador</span
                                >
                                <v-tooltip
                                  top
                                  :color="item.textColor + ' lighten-1'"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      x-small
                                      :color="item.textColor"
                                      icon
                                      depressed
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      <v-icon>mdi-information-outline</v-icon>
                                    </v-btn>
                                  </template>
                                  <p
                                    class="caption black--text font-weight-bold"
                                  >
                                    Tarjeta: 3.6 % + $3 MXN + IVA
                                    <br />
                                    Oxxo: 3.6 % + $3 MXN + IVA
                                    <br />
                                    Transferencia: 3 % + $7 MXN + IVA
                                  </p>
                                </v-tooltip>
                                <br />
                                <span class="caption text-center"
                                  >Más informacion
                                  <a
                                    href="https://stripe.com/mx/pricing"
                                    target="_blank"
                                    class="caption text-decoration-none blue--text"
                                    >aquí</a
                                  >
                                </span>
                              </div>
                            </v-card-text>

                            <v-card-actions class="justify-center pb-4">
                              <v-btn
                                href="mailto:contacto@wechamber.mx"
                                style="text-transform: none"
                                class="accent text-uppercase wDarkerBlue--text font-weight-bold"
                                elevation="1"
                                rounded
                                dark
                                >Contactar Ventas</v-btn
                              >
                            </v-card-actions>
                          </div>

                          <div
                            v-if="
                              item.name == 'Módulo de membresías financiado'
                            "
                            class="py-4"
                          >
                            <v-card-subtitle
                              style="font-size: 16px"
                              class="text-center pa-0 pb-1 font-weight-black"
                            >
                              Módulo de <br />
                              membresías financiado
                              <!-- Cambiado el título aquí -->
                            </v-card-subtitle>

                            <v-list color="transparent" dense>
                              <v-list-item
                                v-for="item in advancep"
                                :key="item"
                                class="pa-0"
                              >
                                <v-list-item-icon class="ma-auto">
                                  <v-icon class="mx-2" color="green"
                                    >mdi-check-bold</v-icon
                                  >
                                </v-list-item-icon>
                                <v-list-item-content class="pa-0">
                                  <p class="mb-0 pa-0" style="font-size: 15px">
                                    {{ item }}
                                  </p>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>

                            <v-card-text class="pa-0 pt-0">
                              <p
                                style="
                                  font-size: 25px;
                                  font-weight: bold;
                                  line-height: 0.7;
                                  text-align: center;
                                "
                              >
                                $ 60.00 MXN <br /><span style="font-size: 10px"
                                  >x miembro</span
                                >
                              </p>

                              <p
                                style="
                                  font-size: 1px;
                                  font-weight: bold;
                                  line-height: 1;
                                  text-align: center;
                                  margin-bottom: 0px;
                                "
                              >
                                <span style="font-size: 10px">
                                  y los $300.00 MXN restantes cuando pague el
                                  miembro
                                  <br /><br />
                                  Total $360.00 MXN al año x miembro</span
                                ><br /><br />
                              </p>
                              <div class="text-center">
                                <span class="caption"
                                  >+ Comisión del procesador</span
                                >
                                <v-tooltip
                                  top
                                  :color="item.textColor + ' lighten-1'"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      x-small
                                      :color="item.textColor"
                                      icon
                                      depressed
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      <v-icon>mdi-information-outline</v-icon>
                                    </v-btn>
                                  </template>
                                  <p
                                    class="caption black--text font-weight-bold"
                                  >
                                    Tarjeta: 3.6 % + $3 MXN + IVA
                                    <br />
                                    Oxxo: 3.6 % + $3 MXN + IVA
                                    <br />
                                    Transferencia: 3 % + $7 MXN + IVA
                                  </p>
                                </v-tooltip>
                                <br />
                                <span class="caption text-center"
                                  >Más informacion
                                  <a
                                    href="https://stripe.com/mx/pricing"
                                    target="_blank"
                                    class="caption text-decoration-none blue--text"
                                    >aquí</a
                                  >
                                </span>
                              </div>
                            </v-card-text>

                            <v-card-actions class="justify-center pb-4">
                              <v-btn
                                href="mailto:contacto@wechamber.mx"
                                style="text-transform: none"
                                class="accent text-uppercase wDarkerBlue--text font-weight-bold"
                                elevation="1"
                                rounded
                                dark
                                >Contactar Ventas</v-btn
                              >
                            </v-card-actions>
                          </div>

                          <div
                            class="pa-3 d-flex flex-column justify-space-around"
                            v-else-if="item.name != 'Avanzado'"
                            style="height: 100%"
                          >
                            <v-card-subtitle class="text-center pb-0">
                              <p
                                class="mb-0 display-2 font-weight-bold wDarkerBlue--text"
                              >
                                {{ item.balance | quantityFormat }}
                              </p>
                            </v-card-subtitle>
                            <v-card-subtitle
                              class="text-center pt-0"
                              :class="`${item.textColor}--text`"
                            >
                              <h3 class="font-weight-black">Créditos</h3>
                              <p style="color: black; font-weight: 600">
                                {{ item.valid }}
                              </p>
                            </v-card-subtitle>
                            <v-card-text class="text-center px-0 pt-8 pb-0">
                              <!-- <p class="title">
                                $ {{ item.price | currency }} MXN
                              </p> -->
                              <p
                                v-if="item.price"
                                style="
                                  font-size: 25px;
                                  font-weight: bold;
                                  line-height: 0.7;
                                "
                              >
                                ${{ item.price | currency
                                }}<span style="font-size: 10px">x crédito</span
                                ><br />
                                <span v-if="item.tprice" style="font-size: 12px"
                                  >${{ item.tprice | currency }} MXN total</span
                                >
                              </p>

                              <span class="caption"
                                >+ Comisión del procesador</span
                              >
                              <v-tooltip
                                top
                                :color="item.textColor + ' lighten-1'"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    x-small
                                    :color="item.textColor"
                                    icon
                                    depressed
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    <v-icon>mdi-information-outline</v-icon>
                                  </v-btn>
                                </template>
                                <p class="caption black--text font-weight-bold">
                                  Tarjeta: 3.6 % + $3 MXN + IVA
                                  <br />
                                  Oxxo: 3.6 % + $3 MXN + IVA
                                  <br />
                                  Transferencia: 3 % + $7 MXN + IVA
                                </p>
                              </v-tooltip>
                              <br />
                              <span class="caption">Más informacion </span>
                              <a
                                href="https://stripe.com/mx/pricing"
                                target="_blank"
                                class="caption text-decoration-none blue--text"
                                >aquí</a
                              >
                            </v-card-text>
                            <v-card-text class="text-center pt-0">
                              <v-icon size="80" :color="item.textColor">
                                {{ item.icon }}
                              </v-icon>
                            </v-card-text>
                            <v-card-actions class="justify-center pb-4">
                              <ActionButton @click="setPayPlan(item)">
                                Comprar
                              </ActionButton>
                            </v-card-actions>
                          </div>
                        </v-card>
                      </v-hover>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
          <!--<div class="pa-5 cards-container">
            <div>
              <v-card
                v-for="{ balance, price } in plans"
                :key="balance"
                min-width="200"
                class="pa-5 ma-10 grey lighten-3 scale d-inline-block"
              >
                <v-card-text class="text-center d-flex flex-column">
                  <span class="text-h3">{{ balance }}</span>
                  <span class="text-subtitle-1 font-weight-bold"
                    >Registros</span
                  >
                  <span class="text-h6 font-weight-bold my-5">{{
                    formatNumber(price)
                  }}</span>
                </v-card-text>
                <v-card-actions class="d-flex justify-center">
                  <ActionButton @click="setPayPlan({ balance, price })">
                    Comprar
                  </ActionButton>
                </v-card-actions>
              </v-card>
            </div>
          </div>-->

          <div class="pr-3 px-sm-5 px-md-10 d-flex flex-column justify-center">
            <div
              v-for="(desc, i) in descriptions"
              :key="i"
              class="d-flex align-center text-justify mb-3"
            >
              <v-icon color="gray" class="mr-2 mr-sm-5"
                >mdi-check-circle</v-icon
              >
              {{ desc }}
            </div>
          </div>
          <v-row
            class="d-flex pb-2 mt-5"
            :class="
              $vuetify.breakpoint.smAndUp ? 'justify-end' : 'justify-center'
            "
          >
            <v-btn
              class="font-weight-bold white--text mx-2"
              color="red"
              rounded
              elevation="0"
              @click="$emit('close')"
            >
              Cerrar
            </v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="payPlan.open"
      width="700"
      scrollable
      @click:outside="$emit('close')"
    >
      <CreditCheckoutVue
        :plan="payPlan"
        @back="closePayPlan()"
        @close="payPlan.open = false"
      />
    </v-dialog>
  </v-row>
</template>
<script>
import ActionButton from "@/components/shared/ActionButton";
import Endpoints from "@/share/Endpoints";
import CreditCheckoutVue from "@/components/dashboard/CreditCheckout/CreditCheckout.vue";

export default {
  components: {
    CreditCheckoutVue,
    ActionButton,
    CreditCheckoutVue,
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    scrollPosition: {
      left: 33,
      x: 3,
    },
    payPlan: {
      open: false,
      balance: 0,
      price: 0,
      purchaseId: "",
    },
    /*plans: [
      {
        balance: 50,
        price: 1100,
      },
      {
        balance: 150,
        price: 2700,
      },
      {
        balance: 500,
        price: 7500,
      },
      {
        balance: 1000,
        price: 12000,
      },
      {
        balance: 3000,
        price: 18000,
      },
      {
        balance: 4000,
        price: 20000,
      },
      {
        balance: 5000,
        price: 25000,
      },
    ],*/
    plans: [
      {
        name: "Free",
        balance: 50,
        valid: "Válidos por un año",
        price: 32.8,
        tprice: 1640,
        src: "",
        btn: "Registrarme gratis",
        color: "grey lighten-3",
        textColor: "secondary",
        icon: "mdi-account",
      },
      {
        name: "Básico",
        balance: 150,
        valid: "Válidos por un año",
        price: 27.8,
        tprice: 4170,
        src: "",
        btn: "Quiero la licencia",
        color: "grey lighten-3",
        textColor: "secondary",
        icon: "mdi-account-multiple",
      },
      {
        name: "Academy",
        balance: 500,
        valid: "Válidos por un año",
        price: 24.18,
        tprice: 12090,
        src: "",
        btn: "Quiero la licencia",
        color: "grey lighten-3",
        textColor: "secondary",
        icon: "mdi-account-multiple",
      },
      {
        name: "Congresos",
        balance: 1000,
        valid: "Válidos por un año",
        price: 19.79,
        tprice: 19790,
        src: "",
        btn: "Quiero la licencia",
        color: "grey lighten-3",
        textColor: "secondary",
        icon: "mdi-account-group",
      },
      {
        name: "Congresos",
        balance: 3000,
        valid: "Válidos por un año",
        price: 16.5,
        tprice: 49500,
        src: "",
        btn: "Quiero la licencia",
        color: "grey lighten-3",
        textColor: "accent",
        icon: "mdi-account-group",
      },
      {
        name: "Avanzado",
        color: "wLightAccent",
        textColor: "secondary",
        icon: "mdi-account",
      },
      {
        name: "Módulo de membresías financiado",
        color: "wLightAccent",
        textColor: "secondary",
        icon: "mdi-account",
      },
    ],
    advancep: [
      "Todas las funciones.",
      "Gestión de capítulos / delegaciones locales.",
      "Credenciales virtuales con QR para miembros.",
      "Permisos/Roles de usuario.",
      "Tablero para miembros.",
      "Mi comunidad",
      "Créditos para eventos",
    ],
    descriptions: [
      '¡Lo hicimos más simple! Solo existe un tipo de crédito, ya sea que tu evento tenga costo, ofrezcas facturación, configures envío de diplomas o solamente registres personas para un evento gratuito, se considerará "un crédito" y se irán consumiendo uno a uno del saldo de tu tablero.',
      "Cada crédito puede usarse para inscribir personas a un curso o un evento.",
      "Puedes recargar un nuevo paquete en todo momento, aunque no hayas consumido todos tus créditos. De cualquier manera, si te sobran puedes usarlos en nuevos eventos o créditos de nuevos miembros. El saldo es tuyo.",
      "Cada paquete tiene una vigencia de 1 año a partir del día en que lo adquieras.",
    ],
  }),
  methods: {
    async setPayPlan(plan) {
      const response = await this.axios({
        method: "post",
        url: Endpoints.purchases,
        data: {
          purchaseInformation: {
            itemQuantity: plan.balance,
          },
        },
      });
      console.log("Esta es la info que me trae desde el back");
      console.log(response.data);

      this.payPlan = {
        balance: response.data.purchase.itemQuantity,
        price: response.data.purchase.total,
        purchaseId: response.data.purchase._id,
        open: true,
      };
      this.$emit("close");
    },
    closePayPlan() {
      this.payPlan = {
        open: false,
        balance: 0,
        price: 0,
      };
      this.$emit("open");
    },
    formatNumber: (number) =>
      new Intl.NumberFormat("es-mx", {
        style: "currency",
        currency: "MXN",
      }).format(number),
    mouseDownHandler(e) {
      const ele = document.getElementById("draggable-scroll");
      ele.style.cursor = "grabbing";
      ele.style.userSelect = "none";

      this.scrollPosition.left = ele.scrollLeft;
      this.scrollPosition.x = e.clientX;

      document.addEventListener("mousemove", this.mouseMoveHandler);
      document.addEventListener("mouseup", this.mouseUpHandler);
    },
    mouseMoveHandler(e) {
      const ele = document.getElementById("draggable-scroll");

      const dx = e.clientX - this.scrollPosition.x;
      ele.scrollLeft = this.scrollPosition.left - dx;
    },
    mouseUpHandler(e) {
      const ele = document.getElementById("draggable-scroll");

      ele.style.cursor = "grab";
      ele.style.removeProperty("user-select");

      document.removeEventListener("mousemove", this.mouseMoveHandler);
      document.removeEventListener("mouseup", this.mouseUpHandler);
    },
  },
};
</script>
<style scoped>
/*
.left-img {
  position: absolute;
  left: 0;
  margin-left: 40px;
}
.subheader {
  max-width: 80%;
  margin: 0 auto;
}
.scale,
.scale:hover {
  transition: 0.15s;
}
.scale:hover {
  transform: scale(1.2);
}
.cards-container {
  overflow-x: scroll;
}
.cards-container > div {
  width: 100%;
  height: 100%;
  overflow-x: scroll;
  white-space: nowrap;
}
.v-card {
  overflow-x: scroll;
}*/

/* Hacer que la tarjeta se haga más grande al pasar el apuntador sobre ella */
.plan-card {
  transition: all 1s;
}

.plan-card:not(.on-hover) {
  transform: scale(0.95);
}

.service-scroll {
  white-space: nowrap;
  overflow-x: scroll;
  cursor: grab;
}

.service-scroll::-webkit-scrollbar {
  display: none;
}

.service-scroll::-webkit-scrollbar-track {
  background-color: transparent;
}

.service-scroll::-webkit-scrollbar-thumb {
  display: none;
}
</style>
