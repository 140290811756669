<template>
  <v-container fluid>
    <div class="ma-auto" v-if="$vuetify.breakpoint.mdAndDown">
      <!-- bar -->
      <v-app-bar fixed app elevate-on-scroll height="50" color="white">
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <v-spacer></v-spacer>

      </v-app-bar>
    </div>
    <div v-if="$vuetify.breakpoint.mdAndDown">
      <MemberForm class="pt-14" edit :member="user" :profilePicture="user.profilePicture" />
    </div>



    <v-row class="d-flex">
      <v-col class="mx-16 px-16" :cols="$vuetify.breakpoint.mdAndDown ? '0' : '1'">
        <!-- Left side navbar flex -->
        <v-navigation-drawer floating app class="brown lighten-5" v-model="drawer">
          <v-list class="d-flex flex-column justify-center align-center overflow-hidden">
            <!-- Avatar miembro -->
            <v-list-item>
              <v-list-item-avatar class="mx-0 mt-5" size="150">
                <v-img :src="user.profilePicture"></v-img>
              </v-list-item-avatar>
            </v-list-item>

            <v-list-item class="font-weight-bold d-flex text-center my-2 DarkerBlue--text">
              <!-- {{ user.company || "N/D" }} -->
            </v-list-item>

            <!-- Listado de items color="#1A237E"-->
            <v-list nav rounded class="brown lighten-5 buton-link">
              <v-list-item-group v-model="selectedItem" class="align-center">
                <v-list-item v-for="(it, i) in items" dense :key="i" :to="it.route" class="px-0 my-0"
                  active-class="white--text wDarkerBlue" :disabled="it.disabled">
                  <v-list-item-icon class="mx-3">
                    <v-icon>{{ it.icon }}</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content class="px-2 py-0">
                    <v-list-item-title>{{ it.title }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-list>
          <!-- Cerrar sesión -->
          <template v-slot:append>
            <div class="mb-10">
              <v-list-item class="justify-center" v-if="user.role == 'MEMBER' && ['62b622c66a7441ae20159d7d', '6297b0fec7bf8b6f826313be'].includes(user.headOffice._id)">
                <v-btn elevation="0" color="#35404c" @click="_goToProfiles()"
                  class="font-weight-bold rounded-pill white--text">Cambiar
                  perfil</v-btn>
              </v-list-item>
              <v-list-item class="justify-center">
                <v-btn elevation="1" depressed text @click="_logout()" class="font-weight-bold rounded-pill">Cerrar
                  sesión</v-btn>
              </v-list-item>
              <v-list-item class="px-0 justify-center">
                <v-img src="@/assets/images/weChamber/poweredby.svg" max-height="120" max-width="120"></v-img>
              </v-list-item>
            </div>
          </template>
        </v-navigation-drawer>
      </v-col>
      <v-col :cols="$vuetify.breakpoint.mdAndUp ? 'auto' : '12'" v-if="$route.path.includes('/member/newAffiliate') ||
        $route.path.includes('/member/renew') ||
        $route.path.includes('/member/payments')
        ? false
        : true
        ">
        <member-status />
      </v-col>
      <v-col v-if="($vuetify.breakpoint.mdAndUp &&
        $route.fullPath.includes('/member/newAffiliate')) ||
        $route.fullPath.includes('/member/renew') ||
        ($vuetify.breakpoint.lgAndUp &&
          $route.fullPath.includes('/member/home'))
        " class="d-flex flex-column justify-end align-end">
        <v-row class="ma-0" justify="end">
          <MemberForm edit :member="user" :profilePicture="user.profilePicture" />
        </v-row>
      </v-col>
    </v-row>

  </v-container>
</template>
<script>
import { mapMutations, mapState, mapActions } from "vuex";
import memberStatus from "@/components/memberDashboard/memberStatus";
import MemberForm from "@/components/shared/MemberForm";
import memberUserSection from "@/components/memberDashboard/memberUserSection.vue";
export default {
  components: {
    memberStatus,
    MemberForm,
    memberUserSection,
  },
  props: {
    items: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      drawer: true,
      branchImage: "",
      selectedItem: 0,
      picture: "",
      //datos de prueba

      itemlist: [
        { title: "Inicio", icon: "mdi-view-dashboard", route: "/member/home" },
        {
          title: "Histórico",
          icon: "mdi-book-open-page-variant",
          route: "/member/history",
        },
        {
          title: "Afiliados vinculados",
          icon: "mdi-account-multiple",
          route: "/member/affiliates",
        },
        {
          title: "Cursos y Eventos",
          icon: "mdi-school",
          route: "/member/events",
        },
      ],
    };
  },
  methods: {
    ...mapMutations("user", ["logout"]),
    ...mapMutations("member", ["delItems"]),
    ...mapActions("member", ["fetchMemberCredentials", "deleteMemberItems"]),
    ...mapActions("membershipUsers", ["fetchMembershipCredentials"]),
    ...mapMutations("membershipProfile", ["removeMembershipProfile"]),
    _logout() {
      this.logout();
      this.deleteMemberItems();
      this.$router.push("/login");
    },
    _goToProfiles() {
      this.delItems()
      this.removeMembershipProfile()
      this.$router.push("/member/affiliation-profile");
    },
    test() {
      this.$router.push({ name: "Member Home", query: { needsPayment: true } });
    },
    goToNationalPayment() {
      this.$router.push({
        name: "National Payment",
        query: { needsPayment: true },
      });
    },
    async fetchBranchImage() {
      const response = await this.axios({
        method: "GET",
        url: `/members/head-office/logo`,
      });
      this.branchImage = response.data.logo;
    },
  },

  computed: {
    ...mapState("member", ["credential"]),
    ...mapState("membershipUsers", ["credential"]),
    ...mapState("user", ["user"]),
    associateComputed() {
      return this.user.role === "MEMBER"
        ? this.$store.state.member.credential.credentialInformation || ""
        : this.$store.state.membershipUsers.credential.credentialInformation ||
        "";
    },
    isAmpi() {
      //dev ampi: 62b622c66a7441ae20159d7d
      const ampi1 = "62b622c66a7441ae20159d7d";
      const ampi2 = "6297b0fec7bf8b6f826313be";
      return this.user.headOffice === ampi1 || this.user.headOffice === ampi2;
    },
  },

  async created() {
    await this.fetchBranchImage();
    if (!this.credential.credentialInformation) {
      if (this.user.role == "MEMBER") {
        await this.fetchMemberCredentials();
      } else {
        await this.fetchMembershipCredentials();
      }
    }
    // console.log(this.$store.state);
  },
};
</script>
<style scoped>
@font-face {
  font-family: "Montserrat-Black";
  src: url("/assets/fonts/Montserrat/MONTSERRAT_Montserrat-Bold.otf") format("opentype");
}
</style>
