<template>
  <v-bottom-sheet v-model="showCookiesAdvice" hide-overlay persistent>
    <v-sheet height="auto" color="light-blue lighten-3" rounded="lg">
      <v-row class="mx-2 my-4 pa-2">
        <span class="font-weight-black">Hablemos de cookies 🍪</span>
        <span class="cookie-text" >
                   Las Cookies son importantes para el correcto funcionamiento del Sitio.
          Para mejorar la experiencia de navegación del Usuario, utilizamos
          cookies para recordar sus datos de inicio de sesión y brindarle un
          inicio seguro, recopilar estadísticas para optimizar la funcionalidad
          del Sitio, así como para ofrecerle contenido personalizado en función
          de sus intereses. Seleccione el botón de “Aceptar Cookies" para
          aceptar las cookies e ir directamente al Sitio o, si requiere de mayor
          información de los tipos de cookies, consulte nuestra
          <router-link to="/politica-de-cookies">Política de Cookies</router-link>.
        </span>
      </v-row>
      <v-row class=" pr-4">
        <v-col cols="12" sm="6" md="3" class="mr-0" > 
          <v-btn
            color="blue darken-2"
            class="ma-2 white--text "
            block
            @click="AcceptCookies()"
          >
            ACEPTAR COOKIES
          </v-btn>
        </v-col>
        <v-col cols="12" sm="6" md="3"  >
          <v-btn
          
            color="blue darken-2"
            class="ma-2 white--text "
            block
          >
            <a
            href="https://www.google.com/"
            class="decoration-none white--text"
            style="text-decoration: none;"
              >RECHAZAR COOKIES</a
            >
          </v-btn>
        </v-col>
      </v-row>
    </v-sheet>
  </v-bottom-sheet>
</template>

<script>
export default {
  data() {
    return {
      showCookiesAdvice: true,
    };
  },
  methods: {
    AcceptCookies() {
      localStorage.setItem("showCookiesAdvice", false);
      this.showCookiesAdvice = false;
    },
  },
  created() {
    if (localStorage.getItem("showCookiesAdvice") === "false") {
      this.showCookiesAdvice = false;
    }
  },
};
</script>

<style>

@media (max-width: 600px) {
  .font-weight-black {
    font-size: 18px;
  }
  .cookie-text {
    text-align: justify;

    font-size: 14px; 
  }
}
.v-bottom-sheet {
  box-shadow: none !important; 

}

.v-sheet {
  border-radius: 8px; 
}

</style>






