export default {
  // AFFILIATIONS
  affiliations: '/affiliations',
  //affiliationMicrositeData: '/affiliations/microsite',
  affiliationMicrositeData: '/microsite/affiliation',
  addAffiliationSponsor: '/affiliations/addSponsor',
  removeAffiliationSponsor: '/affiliations/removeSponsor',
  affiliationTypes: '/affiliation-types/:affiliationId',
  affiliationTypesCreate: '/affiliation-types',
  affiliationTypesAssignItems: '/affiliation-types/:itemId/assign',
  affiliationTypesUnassignItems: '/affiliation-types/:itemId/unassign',
  /**
   * @method PUT
   * @method DELETE
   */
  affiliationTypesUpdate: '/affiliation-types/:typeId',

  // AFFILIATES
  affiliates: '/affiliates/:affiliationId',
  affiliatesCreate: '/affiliates',
  affiliatesUpdate: '/affiliates/:affiliateId',

  // AFFILIATEiNSCRIPTION
  inscriptionCreateOrder: '/affiliation-inscription/order-management/:affiliationType/:affiliateId?',

  // ACCOUNTS
  accountsConnect: '/accounts/account-management',

  // ATTENDEES
  /**
   * @method GET - eventId
   * @method PUT - attendeeId
   */
  attendees: '/attendees/:id',
  attendeesSendTicket: '/attendees/attendee-management/send-ticket',
  attendeesCheckIn: '/attendees/attendee-management/check-in',
  attendeesReport: '/attendees/report/:id',


  // AUTHENTICATION
  login: '/authentication/login',
  passwordRecover: '/authentication/password-recover',
  passwordReset: '/authentication/password-reset',
  validateResetToken: '/authentication/validate-reset-token',
  validateNewUser: '/users/email-confirmation',
  resendEmailValidaton: '/users/resend-email-confirmation',

  // EVENT
  events: '/events',
  //micrositeData: '/events/microsite',
  micrositeData: '/microsite/event',
  addSponsor: '/events/addSponsor',
  removeSponsor: '/events/removeSponsor',
  eventsIndicators: '/events/:eventId/indicators',
  eventsSendDiplomas: '/events/send-diplomas/:eventId',
  publishEvent: '/events/publish-event/:eventId',
  hideEvent: '/events/hide-event/:eventId',

  // FAQS
  faqs: '/faqs/event',
  affiliationFaqs: '/faqs/affiliation',

  // INVOICES
  invoicesGetData: '/invoices/organization',
  invoicesUpdateTaxData: '/invoices/updateTaxInformation',
  invoicesUpdateCustomization: '/invoices/updateCustomizationInformation',
  invoicesUpdateLogo: '/invoices/updateInvoiceLogo',
  invoicesConnect: '/invoices/createFacturapiUser',
  invoicesOrder: '/invoices/order/:orderId',
  invoicesCreate: '/invoices/create-invoice',
  invoicesCreateInternal: '/invoices/create-invoice/internal',
  invoicesDownload: '/invoices/download-invoice',
  invoicesSendEmail: '/invoices/send-email-invoice',
  
  invoicesDownloadInternal: '/invoices/download-invoice-internal',
  invoicesUpdateCSD: '/invoices/updateOrganizationCertificate',

  // TICKETS
  tickets: '/tickets',
  ticketsByEvent: '/tickets/:eventId',

  // EXTRA FIELDS
  extraFieldsTicket: '/registrationFields/ticket/:ticketId',
  extraFieldsAddField: '/registrationFields',
  /**
   * @method PUT
   * @method DELETE
   */
  extraFieldsModifyField: '/registrationFields/:fieldId',
  extraFieldsAffiliation: '/registrationFields/affiliation-type/:affiliationTypeId',

  // CHECKOUT
  checkoutEvent: '/checkout/event/:eventId',
  checkoutCreateOrder: '/checkout/event/order-management',
  checkoutSetTicketToOrder: '/checkout/event/item-management',
  checkoutTicket: '/checkout/event/ticket-management/:eventId',
  checkoutSetPaymentInformation: '/checkout/event/order-management/set-payment-information',
  checkoutSetAttendeeInformation: '/checkout/event/order-management/set-attendee-information',
  checkoutSetAttendeeDocs: '/checkout/event/order-management/set-attendee-docs',


  // CHECKOUT AFFILIATION
  checkoutAffiliationGetInfo: 'checkout/affiliation/:affiliationId',
  checkoutAffiliationGetInfoByType: 'checkout/affiliation-type/:affiliationId',
  checkoutAffiliationCreateOrder: 'checkout/affiliation/order-management',
  checkoutAffiliationItemManagement: 'checkout/affiliation/item-management',
  checkoutAffiliationSetAffiliateInfo: 'checkout/affiliation/order-management/set-affiliate-information',
  checkoutAffiliationFreeOrder: 'payments/confirm-free-order',

  // COUPONS
  /**
   * @method POST
   * @method DELETE
   */
  coupons: '/coupons/event',
  /**
   * @method GET - event id
   * @method PUT - coupon id
   */
  couponsId: '/coupons/event/:id',
  couponValidate: '/coupons/event/coupon-management',
  affiliationCouponsGet: '/coupons/affiliation/:affiliationId',
  /**
   * @method POST
   * @method DELETE
   */
  affiliationCoupons: '/coupons/affiliation',
  affiliationCouponsUpdate: '/coupons/affiliation/:couponId',
  affiliationCouponsValidate: '/coupons/affiliation/coupon-management',

  // EVENTS
  eventById: '/events',

  // PAYMENTS
  cardPayementIntent: '/payments/card/payment-intent',
  cashPaymentIntent: '/payments/cash/payment-intent',
  bankTransferPaymentIntent: '/payments/bank-transfer/payment-intent',
  executeCardPayement: '/payments/card/confirm',
  internalCardPayementIntent: "/payments/card/internal/payment-intent",
  executeInternalCardPayement: "/payments/card/internal/confirm",
  excecuteFreeRegistration: "/payments/confirm-free-order",
  excecuteGetMethodPayment: "/payments/get-methods-payment/:userID",

  // ORDERS
  allOrders: '/orders',
  orders: '/orders/:eventId',
  ordersReport: '/orders/:eventId/report',
  affiliationOrders: '/orders/affiliation/:affiliationId',
  affiliationOrdersReport: '/orders/affiliation/:affiliationId/report',

  affiliationMembershopOrders: '/orders/affiliation-membership/:affiliationId',
  affiliationMembershipOrdersReport: '/orders/affiliation-membership/:affiliationId/report',

  // PAYMENT REFERENCE
  paymentReference: '/orders/set-payment-reference/:orderId',

  // USERS
  /**
   * @method GET
   * @method PUT
   */
  users: '/users',
  usersUpdateProfilePicture: '/users/update-profile-picture',
  usersUpdatePassword: '/users/update-password',
  usersPayouts: '/users/payouts',
  usersPayoutsDetail: '/users/payout/:payoutId/:stripeAccountType',
  usersPayoutsReport: '/users/payouts-report',
  usersIndicators: '/users/indicators',
  usersOrganizationIndicators: '/users/organization-indicators',
  hideOrganizationTutorial: '/users/hide-organization-tutorial',
  userPostMember: '/users/member',

  // PURCHASES
  purchases: '/purchases',

  // Help desk
  helpDesk: '/help-desk',

  // Executives
  executives: '/executives',
  updateExcecutive: '/executives/:id',

  // Members Events
  memberEvents: '/branches/events',
  memberIndicators: '/members/dashboard-indicators',
  memberValidationStatus: '/members/validation-status/:userId',
  memberCredentials: '/members/credential',
  memberItems: '/members/items',
  memberInformation: '/members/information',

  // Member Affiliates
  memberAffiliates: '/membership-users',
  memberAffiliate: '/membership-users/:membershipId/:affiliationId',
  createMemberAffiliate: '/membership-users',
  memberAffiliatesUpdate: '/members/membership-user/:userId',
  memberAffiliatesDelete: '/members/membership-user/:userId',

 
  // Membership Users
  membershipUsers: '/membership-users/credential',

  //My Community
  /**
   * @method POST
   * @method DELETE
   */

  createPublications: '/publications',
  deletePublication: '/publications/:publicationId',

  /**
   * @method GET
   * @method PUT
   */

  allPublications: '/publications/organization',
  memberPublications: '/publications/member',
  publications: '/publications/organization/:publicationId',
  updatePublication: '/publications/:publicationId',
  publishPublication: '/publications/publish/:publicationId',
  
  // Debts
    /**
   * @method GET
   * @method PUT
   */
    getUserDebts: '/debts/:userId',
    editDebt: '/debts/:debtId',
    deleteDebt: '/debts/:debtId',
    addDebt: '/debts/:userId',
    debtAmount: '/debts',
    updateDebtAmount: '/order-management',
};
