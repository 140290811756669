<template>
  <v-container class="ma-5">
    <v-row justify="center">
      <v-col cols="10">
        <v-row justify="space-between">
          <span class="wc-subtitle-2">Datos para el directorio publico</span>

          <v-btn
            rounded
            color="primary"
            :loading="loading"
            @click="saveDirectoryData()"
          >
            <v-icon>mdi-content-save</v-icon>
            Guardar
          </v-btn>
        </v-row>
        <v-row>
          <p class="wc-body-2">
            Los datos de este apartado seran los que alimenten tu perfil publico
            dentro del directorio de miembros de la organización
          </p>
        </v-row>
        <v-row align="center" justify="center">
          <div class="d-flex align-center">
            <span class="mr-3 wc-subtitle-2">
              Ser visible en el directorio publico
            </span>
            <v-switch v-model="directoryData.visible" class="mr-10"> </v-switch>
          </div>

          <div class="d-flex align-center">
            <span class="mr-3 wc-subtitle-2">
              Usar datos de miembro titular
            </span>
            <v-switch v-model="useMemberData" @change="handleUseMemberData()">
            </v-switch>
          </div>
        </v-row>
      </v-col>
    </v-row>

    <v-row align="center" justify="center">
      <v-col cols="4" md="2">
        <v-row class="ma-0 mb-3">
          <span>Imagen de perfil o logotipo</span>
        </v-row>
        <v-row class="ma-0">
          <ImageUploader
            v-if="
              directoryData.logo &&
              directoryData.logo != '' &&
              directoryData.logo != null &&
              directoryData.logo != undefined &&
              directoryData.logo != 'NOT_ASSIGNED'
            "
            :imgUrl="directoryData.logo"
            class="mb-5"
            @save="handleImage"
          />
          <ImageUploader
            v-else
            imgUrl="https://w7.pngwing.com/pngs/211/382/png-transparent-silhouette-avatar-avatar-silhouettes-animals-public-relations-monochrome.png"
            class="mb-5"
            @save="handleImage"
          />
        </v-row>
      </v-col>
      <v-col cols="5" md="4">
        <v-row class="ma-0">
          <v-text-field
            v-model="directoryData.company"
            label="Nombre Comercial"
            solo-inverted
            flat
            rounded
          ></v-text-field>
        </v-row>
        <v-row class="ma-0">
          <v-text-field
            v-model="directoryData.name"
            label="Nombre de representante (opcional)"
            solo-inverted
            flat
            rounded
          ></v-text-field>
        </v-row>
        <v-row class="ma-0">
          <v-text-field
            v-model="directoryData.description"
            label="Descripción de la empresa"
            solo-inverted
            flat
            rounded
          ></v-text-field>
        </v-row>
      </v-col>
      <v-col cols="5" md="4">
        <v-row class="ma-0">
          <v-text-field
            v-model="directoryData.email"
            label="Correo electónico comercial (opcional)"
            solo-inverted
            flat
            rounded
          ></v-text-field>
        </v-row>
        <v-row class="ma-0">
          <v-text-field
            v-model="directoryData.phone"
            label="Telefono comercial (opcional)"
            solo-inverted
            flat
            rounded
          ></v-text-field>
        </v-row>
        <v-row class="ma-0">
          <v-text-field
            v-model="directoryData.url"
            label="URL (link comercial o profesional)"
            solo-inverted
            flat
            rounded
          ></v-text-field>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ImageUploader from "@/components/shared/ImageUploader";
import { mapMutations } from "vuex";

export default {
  name: "DirectoryDataTab",
  components: {
    ImageUploader,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      useMemberData: false,
      directoryData: {
        visible: false,
        name: "",
        company: "",
        description: "",
        email: "",
        phone: "",
        url: "",
        logo: "",
      },
      imageToUpload: null,
    };
  },
  methods: {
    ...mapMutations("notification", ["show"]),
    handleImage(file) {
      this.imageToUpload = file;
    },
    handleUseMemberData() {
      if (this.useMemberData) {
        this.directoryData.name =
          this.user.name + " " + this.user.firstLastname;
        this.directoryData.email = this.user.email;
        this.directoryData.phone = this.user.phone;
        this.directoryData.logo = this.user.profilePicture;
        this.directoryData.company = this.user.company;
      } else {
        this.directoryData.name = this.user.directoryData?.name || "";
        this.directoryData.email = this.user.directoryData?.email || "";
        this.directoryData.phone = this.user.directoryData?.phone || "";
        this.directoryData.logo = this.user.directoryData?.logo || "";
        this.directoryData.company = this.user.directoryData?.company || "";
        this.directoryData.description =
          this.user.directoryData?.description || "";
      }
    },
    async saveDirectoryData() {
      try {
        this.loading = true;

        const response = await this.axios({
          method: "PUT",
          url: "/users/directory-data",
          data: {
            directoryData: this.directoryData,
          },
        });

        if (
          this.imageToUpload != this.user.profilePicture &&
          this.imageToUpload != this.user.directoryData?.logo &&
          this.imageToUpload != null
        ) {
          const formData = new FormData();
          formData.append("file", this.imageToUpload);

          const logoResponse = await this.axios.put(
            "/users/directory-logo",
            formData
          );

          if (logoResponse.status === 200 && response.status === 200) {
            this.show({
              text: "Datos guardados correctamente",
              color: "success",
            });
          }
        } else {
          if (response.status === 200) {
            this.show({
              text: "Datos guardados correctamente",
              color: "success",
            });
          }
        }
      } catch (error) {
        this.show({
          text: "Ocurrio un error al guardar los datos",
          color: "error",
        });
      } finally {
        this.loading = false;
      }
    },
  },
  created() {
    this.handleUseMemberData();
  },
};
</script>

<style>
.v-text-field--rounded {
  border-radius: 20px;
}
</style>